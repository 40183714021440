import { filter, chunk } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef, View } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import ArrowBack from '@iconify/icons-eva/arrow-ios-back-outline';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Backdrop,
  Paper
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar, MoreMenu } from '../../components/layin';
import ReceiptModal from './ReceiptModal';
import StorageModal from './StorageModal';
import CancelModal from './CanelModal';
import EditModal from './LayinModal';
import ConfDialog from '../../components/ConfDialog';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'order_dt', label: '発注日', alignRight: false },
  { id: 'partner_code', label: 'パートナー', alignRight: false },
  { id: 'product_code', label: '商品', alignRight: false },
  { id: 'lot_number', label: ['ロット', <br />, '番号'], alignRight: false },
  { id: 'order_quantity', label: '発注数', alignRight: false },
  { id: 'ship_dt', label: '発送日', alignRight: false },
  { id: 'stock_expected_dt', label: ['到着', <br />, '予定日'], alignRight: false },
  { id: 'stock_dt', label: '入荷日', alignRight: false },
  { id: 'stock_quantity', label: '入荷数', alignRight: false },
  { id: 'expiry_dt', label: ['消費', <br />, '期限'], alignRight: false },
  { id: 'status', label: '状況', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, status, partnerCode, dt) {
  if (query) {
    array = filter(
      array,
      (_target) =>
        _target.with_product &&
        (_target.with_product.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          _target.with_product.code.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    );
  }
  if (status !== -1) {
    array = filter(array, (_target) => _target.status_label === status);
  }
  if (partnerCode !== -1) {
    array = filter(array, (_target) => _target.partner_code === partnerCode);
  }
  if (dt !== -1) {
    array = filter(array, (_target) => _target.stock_expected_dt === dt);
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Layin() {
  const navigate = useNavigate();

  const { SystemLib } = window;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [modalCancel, setModalCancel] = useState(false);
  const [modalReceipt, setModalReceipt] = useState(false);
  const [modalStorage, setModalStorage] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [list, setList] = useState([]);
  const [updateDt, setUpdateDt] = useState(moment().unix());

  const [currentDeviceCode, setCurrentDeviceCode] = useState('');
  const [currentColumnNumber, setCurrentColumnNumber] = useState(0);
  const [currentMax, setCurrentMax] = useState(0);

  const [currentId, setCurrentId] = useState(0);
  const [currentProduct, setCurrentProduct] = useState({ code: null, name: '' });

  const [isLoading, setIsLoading] = useState(false);
  const [conf, setConf] = useState(false);
  const [confAll, setConfAll] = useState(false);
  const [confOk, setConfOk] = useState(() => {});
  const diagOkproc = useRef(false);

  // フィルタ用
  const [status, setStatus] = useState(-1);
  const [partnerCode, setPartnerCode] = useState(-1);
  const [dt, setdt] = useState(-1);

  useEffect(() => {
    getList();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredItems.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredItems = applySortFilter(
    list,
    getComparator(order, orderBy),
    filterName,
    status,
    partnerCode,
    dt
  );

  const isItemNotFound = filteredItems.length === 0;

  const useStyles = makeStyles({
    tableCell: {
      height: 10
    }
  });

  const getList = async () => {
    setIsLoading(true);
    const { data } = await SystemLib.api('layin/list', {}, 'post');
    if (data.result) {
      setList(data.data);
    }
    setIsLoading(false);
  };

  const getItem = async (id) => {
    const { data } = await SystemLib.api(
      `layin/item`,
      {
        id
      },
      'post'
    );
    if (data.result) {
      let flag = false;
      if (list) {
        const arr = list.slice();
        arr.forEach((v, i) => {
          if (v.id === id) {
            arr[i] = data.data;
            setList(arr);
            flag = true;
          }
        });
      }
      if (!flag) {
        // setOrderBy(null);
        const arr = list.slice();
        arr.unshift(data.data);
        setList(arr);
      }
    }
  };

  // const deleteItems = async (ids) => {
  //   const tmp = chunk(ids, 1000);
  //   const p = [];
  //   tmp.forEach(async (arr) => {
  //     console.log(arr);
  //     p.push(
  //       SystemLib.api(
  //         `stock/delete`,
  //         {
  //           ids: arr
  //         },
  //         'post'
  //       )
  //     );
  //   });
  //   await Promise.all(p);
  //   const newItems = list.filter((v) => !ids.includes(v.id));
  //   setList(newItems);
  // };

  const reload = async (id) => {
    getItem(id);
  };

  const reload_all = async () => {
    getList();
  };

  const classes = useStyles();

  return (
    <>
      <Page title="仕入・荷受・保管の実施">
        <Container sx={{ position: 'relative' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              仕入・荷受・保管の実施
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="outlined"
              startIcon={<Icon icon={ArrowBack} />}
              onClick={() => {
                navigate(-1);
              }}
            >
              戻る
            </Button>
          </Stack>
          <Card>
            <ListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDelete={() => {
                setConfAll(true);
              }}
              onChangeStatus={(status) => {
                setStatus(status);
              }}
              onChangePartnerCode={(code) => {
                setPartnerCode(code);
              }}
              onChangeDate={(dt) => {
                setdt(dt);
              }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={filteredItems.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredItems
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          order_dt,
                          order_quantity,
                          ship_dt,
                          stock_expected_dt,
                          stock_dt,
                          lot_number,
                          expiry_dt,
                          stock_quantity,
                          status,
                          status_label,
                          with_partner,
                          with_product,
                          partner_code
                        } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;
                        let status_color = null;
                        if (status === 'shipping') {
                          status_color = '#fff8dc';
                        }
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            {/* <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, id)}
                              />
                            </TableCell> */}
                            <TableCell scope="row" sx={{ paddingLeft: 3 }}>
                              {moment(order_dt).format('M/D')}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {[with_partner.code, <br />, with_partner.name]}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {[with_product.code, <br />, with_product.name]}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {lot_number}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {order_quantity}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {ship_dt && moment(ship_dt).format('M/D')}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {stock_expected_dt && moment(stock_expected_dt).format('M/D')}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {stock_dt && moment(stock_dt).format('M/D')}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {stock_quantity > 0 && stock_quantity}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {expiry_dt && moment(expiry_dt).format('M/D')}
                            </TableCell>
                            <TableCell
                              scope="row"
                              padding="normal"
                              align="center"
                              sx={{ backgroundColor: status_color }}
                            >
                              {status_label}
                            </TableCell>
                            <TableCell
                              scope="row"
                              padding="none"
                              align="center"
                              sx={{ paddingRight: 2, whiteSpace: 'nowrap' }}
                            >
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                paddingLeft={1}
                                justifyContent="center"
                              >
                                {status === 'order' && (
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                      setUpdateDt(moment().unix());
                                      setCurrentId(id);
                                      setModalCancel(true);
                                    }}
                                  >
                                    キャンセル
                                  </Button>
                                )}
                                {status === 'shipping' && (
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    color="success"
                                    onClick={() => {
                                      setUpdateDt(moment().unix());
                                      setCurrentId(id);
                                      setCurrentProduct(with_product);
                                      setCurrentMax(order_quantity);
                                      setModalReceipt(true);
                                    }}
                                  >
                                    荷受
                                  </Button>
                                )}
                                {status === 'receipt' && (
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    color="info"
                                    onClick={() => {
                                      setUpdateDt(moment().unix());
                                      setCurrentId(id);
                                      setCurrentProduct(with_product);
                                      setCurrentMax(stock_quantity);
                                      setModalStorage(true);
                                    }}
                                  >
                                    保管
                                  </Button>
                                )}
                              </Stack>
                            </TableCell>
                            <TableCell align="right" padding="none">
                              <MoreMenu
                                itemId={id}
                                onEdit={(id) => {
                                  setCurrentId(id);
                                  setUpdateDt(moment().unix());
                                  setModalEdit(true);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={12} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isItemNotFound && !isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {isItemNotFound && isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <Paper>
                            <Typography gutterBottom align="center" variant="subtitle1">
                              読み込み中
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
              component="div"
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="表示件数"
            />
          </Card>
        </Container>
        {modalCancel && (
          <CancelModal
            targetId={currentId}
            open={modalCancel}
            onClose={() => {
              setModalCancel(false);
            }}
            updateDt={updateDt}
            reload={(id) => {
              reload(id);
            }}
          />
        )}
        {modalReceipt && (
          <ReceiptModal
            targetId={currentId}
            quantity={currentMax}
            product={currentProduct}
            open={modalReceipt}
            onClose={() => {
              setModalReceipt(false);
            }}
            updateDt={updateDt}
            // reload={(id) => {
            reload_all={() => {
              // reload(id);
              reload_all();
            }}
          />
        )}
        {modalStorage && (
          <StorageModal
            targetId={currentId}
            targetProduct={currentProduct}
            targetMax={currentMax}
            open={modalStorage}
            onClose={() => {
              setModalStorage(false);
            }}
            updateDt={updateDt}
            reload={(id) => {
              reload(id);
            }}
          />
        )}
        {modalEdit && (
          <EditModal
            targetId={currentId}
            open={modalEdit}
            onClose={() => {
              setModalEdit(false);
            }}
            updateDt={updateDt}
            reload={(id) => {
              reload(id);
            }}
          />
        )}
        <ConfDialog
          open={conf}
          onOk={() => {
            if (!diagOkproc.current) {
              diagOkproc.current = true;
              confOk();
              setConf(false);
              diagOkproc.current = false;
            }
          }}
          onCancel={() => {
            setConf(false);
          }}
          onClose={() => {
            setConf(false);
          }}
        >
          実行してもよろしいでしょうか？
        </ConfDialog>
        <ConfDialog
          open={confAll}
          onOk={() => {
            if (!diagOkproc.current) {
              diagOkproc.current = true;
              confOk();
              setSelected([]);
              setConfAll(false);
              diagOkproc.current = false;
            }
          }}
          onCancel={() => {
            setConfAll(false);
          }}
          onClose={() => {
            setConfAll(false);
          }}
        >
          実行してもよろしいでしょうか？ ({selected.length}件)
        </ConfDialog>
      </Page>
    </>
  );
}
