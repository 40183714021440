import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Paper
} from '@mui/material';
import Draggable, { DraggableCore } from 'react-draggable';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

import { CollectListHead } from '../../components/stock';
import ConfDialog from '../../components/ConfDialog';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function StorageModal({
  targetDeviceCode,
  targetColumnNumber,
  targetMax,
  open,
  onClose,
  updateDt,
  reload
}) {
  const { SystemLib } = window;
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [conf, setConf] = useState(false);
  const [confOk, setConfOk] = useState(() => () => {});
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const diagOkproc = useRef(false);
  const valids = useRef({});
  const vals = useRef({});
  const [err, setErr] = useState('');

  useEffect(() => {}, []);

  useEffect(() => {
    setList([]);
    if (targetDeviceCode) {
      _getList(targetDeviceCode, targetColumnNumber);
    }
    valids.current = {};
    vals.current = {};
  }, [targetDeviceCode, targetColumnNumber, updateDt]);

  const _getList = async (targetDeviceCode, targetColumnNumber) => {
    setIsLoading(true);
    const {
      data: { result, data }
    } = await SystemLib.api(
      `stock/storage`,
      {
        device_code: targetDeviceCode,
        column_number: targetColumnNumber
      },
      'post'
    );
    setIsLoading(false);
    if (result) {
      setList(data);
    }
  };

  const _setState = async (id, status) => {
    const {
      data: { result }
    } = await SystemLib.api(
      `stock/column/status`,
      {
        id,
        status
      },
      'post'
    );
    if (result) {
      _getList(targetDeviceCode, targetColumnNumber);
    }
  };

  const TABLE_HEAD = [
    { id: 'storage_code', label: '倉庫コード', alignRight: false },
    { id: 'product_code', label: '商品コード', alignRight: false },
    { id: 'lot_number', label: 'ロット番号', alignRight: false },
    { id: 'product_name', label: '商品名', alignRight: false },
    { id: 'quantity', label: '在荷量', alignRight: true },
    { id: 'expiry_dt', label: '消費期限', alignRight: false },
    { id: 'alarm_days', label: ['アラーム', <br />, '期限'], alignRight: false },
    { id: 'expiry_days', label: '残日数', alignRight: true },
    { id: 'exp_status', label: '状態', alignRight: true },
    { id: 'num', label: '出荷数', alignRight: true }
  ];

  const _valid = () => {
    let ch = true;
    Object.keys(valids.current).forEach((key) => {
      if (!valids.current[key]) ch = false;
    });
    if (ch) {
      let sum = 0;
      Object.keys(vals.current).forEach((key) => {
        sum += Number(vals.current[key]);
      });
      if (sum > targetMax) {
        ch = false;
      } else if (sum === 0) {
        ch = false;
      }
    }
    setIsValid(ch);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
          <Draggable
            cancel="table,button"
            defaultPosition={{ x: 0, y: 0 }}
            positionOffset={{ x: '-50%', y: '-50%' }}
          >
            <Box sx={styles.box} spacing={2} maxHeight="80%">
              <Stack direction="row" justifyContent="center" alignItems="center" width="100%">
                <Typography fontSize={18} align="center" color="#2e8b57" sx={{ marginRight: 2 }}>
                  {targetDeviceCode}
                </Typography>
                <Typography fontSize={16} align="center" color="#2e8b57">
                  補充可能数：{targetMax}
                </Typography>
              </Stack>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <CollectListHead headLabel={TABLE_HEAD} />
                    <TableBody>
                      {list.map((row, index) => (
                        <ItemRow
                          key={index}
                          row={row}
                          onChange={(v) => {
                            vals.current[row.id] = v;
                            _valid();
                          }}
                          onValid={(v) => {
                            valids.current[row.id] = v;
                            _valid();
                          }}
                        />
                      ))}
                      {isLoading === false && list.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <Paper>
                              <Typography gutterBottom align="center" variant="subtitle1">
                                在荷がありません
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                      {isLoading && (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <Paper>
                              <Typography gutterBottom align="center" variant="subtitle1">
                                読み込み中
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <Box textAlign="center" sx={{ marginTop: 3 }}>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!isValid}
                  onClick={() => {
                    console.log(vals.current);
                    setConfOk(() => async () => {
                      setIsLoading(true);
                      const {
                        data: { result, data }
                      } = await SystemLib.api(
                        `stock/storage/ship`,
                        {
                          device_code: targetDeviceCode,
                          column_number: targetColumnNumber,
                          stocks: vals.current
                        },
                        'post'
                      );
                      setIsLoading(false);
                      if (result) {
                        reload(targetDeviceCode, targetColumnNumber);
                        onClose();
                      } else {
                        alert('登録に失敗しました');
                      }
                    });
                    setConf(true);
                  }}
                >
                  出荷指示
                </LoadingButton>
              </Box>
            </Box>
          </Draggable>
        </LocalizationProvider>
      </Modal>
      <ConfDialog
        open={conf}
        onOk={() => {
          if (!diagOkproc.current) {
            diagOkproc.current = true;
            confOk();
            setConf(false);
            diagOkproc.current = false;
          }
        }}
        onCancel={() => {
          setConf(false);
        }}
        onClose={() => {
          setConf(false);
        }}
      >
        実行してもよろしいでしょうか？
      </ConfDialog>
    </>
  );
}

function ItemRow({ row, onChange, onValid }) {
  const {
    id,
    storage_code,
    product_code,
    product_name,
    lot_number,
    expiry_dt,
    alarm_days,
    expiry_days,
    quantity,
    exp_status,
    status
  } = row;
  let exp_color = null;
  let exp_label = '適正';
  switch (exp_status) {
    case 'expired':
      exp_color = '#fad9d2';
      exp_label = '期限切れ';
      break;
    case 'warning':
      exp_color = '#fad9d2';
      exp_label = '回収必須';
      break;
    case 'caution':
      exp_color = '#fafad2';
      exp_label = '回収注意';
      break;
    default:
      break;
  }

  const yupNum = Yup.number()
    .typeError('数字のみ')
    .integer('整数のみ')
    .min(0, '0以上')
    .max(quantity, `${quantity}以下`)
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));

  const FormSchema = Yup.object().shape({
    num: yupNum.required('必須')
  });

  const formik = useFormik({
    initialValues: {
      num: 0
    },
    validationSchema: FormSchema,
    onSubmit: async () => {}
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  useEffect(() => {
    onValid(isValid);
  }, [isValid]);

  return (
    <TableRow hover key={id} tabIndex={-1}>
      <TableCell scope="row>">{storage_code}</TableCell>
      <TableCell scope="row>">{product_code}</TableCell>
      <TableCell scope="row">{lot_number}</TableCell>
      <TableCell scope="row">{product_name}</TableCell>
      <TableCell scope="row" align="center">
        <Typography color="#00f" fontSize="0.875rem">
          {quantity}
        </Typography>
      </TableCell>
      <TableCell scope="row">{expiry_dt}</TableCell>
      <TableCell scope="row">
        {moment(expiry_dt).subtract(alarm_days, 'd').format('YYYY-MM-DD')}
      </TableCell>
      <TableCell scope="row" align="center">
        {expiry_days}
      </TableCell>
      <TableCell
        scope="row"
        padding="none"
        align="center"
        sx={{
          backgroundColor: exp_color
        }}
      >
        {exp_label}
      </TableCell>
      <TableCell scope="row" padding="none" align="center" width={90}>
        {(exp_status === 'normal' || exp_status === 'caution') && (
          <TextField
            variant="outlined"
            size="small"
            sx={{ marginLeft: 1 }}
            {...getFieldProps('num')}
            error={Boolean(touched.num && errors.num)}
            helperText={touched.num && errors.num}
            onChange={(v) => {
              onChange(v.target.value);
              formik.handleChange(v);
            }}
            value={formik.values.num}
          />
        )}
        {!(exp_status === 'normal' || exp_status === 'caution') && <Typography>ー</Typography>}
      </TableCell>
    </TableRow>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
