import { filter, chunk } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef, View } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import ArrowBack from '@iconify/icons-eva/arrow-ios-back-outline';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Backdrop,
  Paper
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar, MoreMenu } from '../../components/approve';
import ConfDialog from '../../components/ConfDialog';
import DetailModal from './DetailModal';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'ym', label: '月度', alignRight: false },
  { id: 'partner_code', label: 'パートナー', alignRight: false },
  { id: 'sales_amount', label: '売上額', alignRight: false },
  { id: 'profit_amount', label: '収益額', alignRight: false },
  { id: 'fee_amount', label: '管理費', alignRight: false },
  { id: 'status_pe', label: ['状況', <br />, '(PE)'], alignRight: false },
  { id: 'status_cl', label: ['状況', <br />, '(パートナー)'], alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, status_pe, status_cl, partnerCode, y, m) {
  if (query) {
    array = filter(
      array,
      (_target) =>
        _target.with_product &&
        (_target.with_product.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          _target.with_product.code.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    );
  }
  if (status_pe !== -1) {
    array = filter(array, (_target) => _target.status_pe === status_pe);
  }
  if (status_cl !== -1) {
    array = filter(array, (_target) => _target.status_cl === status_cl);
  }
  if (partnerCode !== -1) {
    array = filter(array, (_target) => _target.partner_code === partnerCode);
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Approve() {
  const navigate = useNavigate();

  const { SystemLib } = window;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [list, setList] = useState([]);
  const [updateDt, setUpdateDt] = useState(moment().unix());

  const [isLoading, setIsLoading] = useState(false);
  const [conf, setConf] = useState(false);
  const [confAll, setConfAll] = useState(false);
  const [confOk, setConfOk] = useState(() => {});
  const diagOkproc = useRef(false);

  const [modal, setModal] = useState(false);
  const [currentYm, setCurrentYm] = useState('');
  const [currentPartnerCode, setCurrentPartnerCode] = useState('');

  // フィルタ用
  const [status_pe, setStatusPe] = useState(-1);
  const [status_cl, setStatusCl] = useState(-1);
  const [partnerCode, setPartnerCode] = useState(-1);
  const [y, setY] = useState(moment().subtract(1, 'months').format('YYYY'));
  const [m, setM] = useState(moment().subtract(1, 'months').format('MM'));

  const [sum_profit, setSumProfit] = useState(0);
  const [sum_sales, setSumSales] = useState(0);
  const [sum_fee, setSumFee] = useState(0);

  useEffect(() => {
    // getList();
  }, []);

  useEffect(() => {
    getList();
  }, [y, m]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredItems.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredItems = applySortFilter(
    list,
    getComparator(order, orderBy),
    filterName,
    status_pe,
    status_cl,
    partnerCode,
    y,
    m
  );

  useEffect(() => {
    if (filteredItems) {
      let sum_pt = 0;
      let sum_sl = 0;
      let sum_fe = 0;
      filteredItems.forEach((v) => {
        sum_pt += Number(v.profit_amount);
        sum_sl += Number(v.sales_amount);
        sum_fe += Number(v.fee_amount);
      });
      setSumProfit(sum_pt);
      setSumSales(sum_sl);
      setSumFee(sum_fe);
    }
  }, [filteredItems]);

  const isItemNotFound = filteredItems.length === 0;

  const useStyles = makeStyles({
    tableCell: {
      height: 10
    }
  });

  const getList = async () => {
    setIsLoading(true);
    const { data } = await SystemLib.api(
      'approve/list',
      {
        y,
        m
      },
      'post'
    );
    if (data.result) {
      if (data.data) {
        data.data.forEach((v) => {
          v.id = `${v.ym}_${v.partner_code}`;
        });
      }
      console.log(data.data);
      setList(data.data);
    }
    setIsLoading(false);
  };

  const getItem = async (ym, partner_code) => {
    const tmp = ym.split('-');
    const y = tmp[0];
    const m = tmp[1];
    const { data } = await SystemLib.api(
      `approve/list`,
      {
        y,
        m,
        partner_code
      },
      'post'
    );
    console.log(data);
    if (data.result) {
      let flag = false;
      if (list) {
        const arr = list.slice();
        arr.forEach((v, i) => {
          if (v.ym === ym && v.partner_code === partner_code) {
            data.data[0].id = `${v.ym}_${v.partner_code}`;
            // eslint-disable-next-line prefer-destructuring
            arr[i] = data.data[0];
            setList(arr);
            flag = true;
          }
        });
      }
      if (!flag) {
        // setOrderBy(null);
        const arr = list.slice();
        data.data[0].id = `${data.data[0].ym}_${data.data[0].partner_code}`;
        arr.unshift(data.data[0]);
        setList(arr);
      }
    }
  };

  // const deleteItems = async (ids) => {
  //   const tmp = chunk(ids, 1000);
  //   const p = [];
  //   tmp.forEach(async (arr) => {
  //     console.log(arr);
  //     p.push(
  //       SystemLib.api(
  //         `stock/delete`,
  //         {
  //           ids: arr
  //         },
  //         'post'
  //       )
  //     );
  //   });
  //   await Promise.all(p);
  //   const newItems = list.filter((v) => !ids.includes(v.id));
  //   setList(newItems);
  // };

  const reload = async (ym, partner_code) => {
    getItem(ym, partner_code);
  };

  const classes = useStyles();

  const numberWithComma = new Intl.NumberFormat();
  const profitSumFormat = numberWithComma.format(sum_profit);
  const salesSumFormat = numberWithComma.format(sum_sales);
  const feeSumFormat = numberWithComma.format(sum_fee);

  return (
    <>
      <Page title="売上承認">
        <Container sx={{ position: 'relative' }}>
          <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={5} mb={1}>
            <Typography variant="h4">売上承認</Typography>
            <Typography variant="h6">売上合計：{salesSumFormat}円</Typography>
            <Typography variant="h6" color="#2e8b57">
              収益合計：{profitSumFormat}円
            </Typography>
            <Typography variant="h6" color="#ff7f50">
              管理費合計：{feeSumFormat}円
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="outlined"
              startIcon={<Icon icon={ArrowBack} />}
              onClick={() => {
                navigate(-1);
              }}
            >
              戻る
            </Button>
          </Stack>
          <Card>
            <ListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDelete={() => {
                setConfAll(true);
              }}
              onChangeStatusPe={(status) => {
                setStatusPe(status);
              }}
              onChangeStatusCl={(status) => {
                setStatusCl(status);
              }}
              onChangePartnerCode={(code) => {
                setPartnerCode(code);
              }}
              onChangeY={(dt) => {
                setY(dt);
              }}
              onChangeM={(dt) => {
                setM(dt);
              }}
              onAllApprove={async () => {
                setIsLoading(true);
                const {
                  data: { result, data }
                } = await SystemLib.api(
                  `approve/approve`,
                  {
                    ids: selected
                  },
                  'post'
                );
                setIsLoading(false);
                if (result) {
                  setSelected([]);
                  getList();
                } else {
                  alert('失敗しました');
                }
              }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={filteredItems.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredItems
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          ym,
                          partner_code,
                          sales_amount,
                          profit_amount,
                          fee_amount,
                          approve,
                          with_partner
                        } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;
                        const numberWithComma = new Intl.NumberFormat();
                        const salesFormat = numberWithComma.format(sales_amount);
                        const profitFormat = numberWithComma.format(profit_amount);
                        const feeFormat = numberWithComma.format(fee_amount);
                        let statusLabelPe = '';
                        let statusLabelCl = '';
                        if (approve !== null) {
                          statusLabelPe = '承認済';
                          if (approve.status === 'approve_cl') {
                            statusLabelCl = '承認済';
                          } else {
                            statusLabelCl = '未承認';
                          }
                        } else {
                          statusLabelPe = '未承認';
                          statusLabelCl = '未承認';
                        }
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                key={id}
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, id)}
                              />
                            </TableCell>
                            <TableCell scope="row" sx={{ paddingLeft: 3 }}>
                              {ym}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {[with_partner.code, <br />, with_partner.name]}
                            </TableCell>
                            <TableCell scope="row" padding="normal" align="right">
                              {salesFormat}
                            </TableCell>
                            <TableCell scope="row" padding="normal" align="right">
                              {profitFormat}
                            </TableCell>
                            <TableCell scope="row" padding="normal" align="right">
                              {feeFormat}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {statusLabelPe}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {statusLabelCl}
                            </TableCell>
                            <TableCell
                              scope="row"
                              padding="none"
                              align="center"
                              sx={{ paddingRight: 2, whiteSpace: 'nowrap' }}
                            >
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                paddingLeft={1}
                                justifyContent="center"
                              >
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="info"
                                  onClick={() => {
                                    setCurrentYm(ym);
                                    setCurrentPartnerCode(partner_code);
                                    setUpdateDt(moment().unix());
                                    setModal(true);
                                  }}
                                >
                                  明細
                                </Button>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="success"
                                  onClick={() => {
                                    setConfOk(() => async () => {
                                      setIsLoading(true);
                                      const {
                                        data: { result, data }
                                      } = await SystemLib.api(
                                        `approve/approve`,
                                        {
                                          ids: [id]
                                        },
                                        'post'
                                      );
                                      setIsLoading(false);
                                      if (result) {
                                        reload(ym, partner_code);
                                      } else {
                                        alert('失敗しました');
                                      }
                                    });
                                    setConf(true);
                                  }}
                                  disabled={approve !== null}
                                >
                                  売上承認する
                                </Button>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={8} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isItemNotFound && !isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {isItemNotFound && isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                          <Paper>
                            <Typography gutterBottom align="center" variant="subtitle1">
                              読み込み中
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
              component="div"
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="表示件数"
            />
          </Card>
        </Container>
        {modal && (
          <DetailModal
            ym={currentYm}
            partner_code={currentPartnerCode}
            open={modal}
            onClose={() => {
              setModal(false);
            }}
            updateDt={updateDt}
          />
        )}
        <ConfDialog
          open={conf}
          onOk={() => {
            if (!diagOkproc.current) {
              diagOkproc.current = true;
              confOk();
              setConf(false);
              diagOkproc.current = false;
            }
          }}
          onCancel={() => {
            setConf(false);
          }}
          onClose={() => {
            setConf(false);
          }}
        >
          実行してもよろしいでしょうか？
        </ConfDialog>
        <ConfDialog
          open={confAll}
          onOk={() => {
            if (!diagOkproc.current) {
              diagOkproc.current = true;
              confOk();
              setSelected([]);
              setConfAll(false);
              diagOkproc.current = false;
            }
          }}
          onCancel={() => {
            setConfAll(false);
          }}
          onClose={() => {
            setConfAll(false);
          }}
        >
          実行してもよろしいでしょうか？ ({selected.length}件)
        </ConfDialog>
      </Page>
    </>
  );
}
