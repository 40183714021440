import { filter, chunk } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef, View } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import ArrowBack from '@iconify/icons-eva/arrow-ios-back-outline';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Backdrop,
  Paper
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar, MoreMenu } from '../../components/device';
import EditModal from './DeviceModal';
import ConfDialog from '../../components/ConfDialog';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'code', label: 'デバイスコード', alignRight: false },
  { id: 'name', label: 'デバイス名', alignRight: false },
  {
    id: 'organizer',
    label: ['デバイス', <br />, '設置事業体'],
    alignRight: false
  },
  { id: 'place_1', label: ['デバイス', <br />, '設置場所1'], alignRight: false },
  { id: 'code_csv', label: ['CSV内', <br />, 'デバイスコード'], alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (query) {
    array = filter(
      array,
      (_target) =>
        _target.code.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _target.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        (_target.organizer &&
          _target.organizer.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
        (_target.place_1 && _target.place_1.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
        (_target.place_2 && _target.place_2.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
        (_target.place_3 && _target.place_3.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    );
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Device() {
  const navigate = useNavigate();

  const { SystemLib } = window;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [modal, setModal] = useState(false);
  const [list, setList] = useState([]);
  const [currentTargetId, setCurrentTargetId] = useState(0);
  const [updateDt, setUpdateDt] = useState(moment().unix());

  const [isLoading, setIsLoading] = useState(false);
  const [conf, setConf] = useState(false);
  const [confDelAll, setConfDelAll] = useState(false);
  const diagOkproc = useRef(false);

  useEffect(() => {
    getList();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredItems.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredItems = applySortFilter(list, getComparator(order, orderBy), filterName);

  const isItemNotFound = filteredItems.length === 0;

  const useStyles = makeStyles({
    tableCell: {
      height: 10
    }
  });

  const getList = async () => {
    setIsLoading(true);
    const { data } = await SystemLib.api('device', {}, 'get');
    if (data.result) {
      setList(data.data);
    }
    setIsLoading(false);
  };

  const getItem = async (id) => {
    const { data } = await SystemLib.api(`device/${id}`, {}, 'get');
    if (data.result) {
      let flag = false;
      if (list) {
        const arr = list.slice();
        arr.forEach((v, i) => {
          if (v.id === id) {
            arr[i] = data.data;
            setList(arr);
            flag = true;
          }
        });
      }
      if (!flag) {
        setOrderBy(null);
        const arr = list.slice();
        arr.unshift(data.data);
        setList(arr);
      }
    }
  };

  const deleteItems = async (ids) => {
    const tmp = chunk(ids, 1000);
    const p = [];
    tmp.forEach(async (arr) => {
      console.log(arr);
      p.push(
        SystemLib.api(
          `device/delete`,
          {
            ids: arr
          },
          'post'
        )
      );
    });
    await Promise.all(p);
    const newItems = list.filter((v) => !ids.includes(v.id));
    setList(newItems);
  };

  const reload = async (id) => {
    getItem(id);
  };

  const classes = useStyles();

  return (
    <>
      <Page title="デバイスマスター管理">
        <Container sx={{ position: 'relative' }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            spacing={4}
          >
            <Typography variant="h4" gutterBottom>
              デバイスマスター管理
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="outlined"
              startIcon={<Icon icon={ArrowBack} />}
              onClick={() => {
                navigate(-1);
              }}
            >
              戻る
            </Button>
            <Button
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={() => {
                setCurrentTargetId(0);
                setUpdateDt(moment().unix());
                setModal(true);
              }}
            >
              新規登録
            </Button>
          </Stack>

          <Card>
            <ListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDelete={() => {
                setConfDelAll(true);
              }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={filteredItems.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredItems
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          code,
                          name,
                          organizer,
                          place_1,
                          place_2,
                          place_3,
                          contract,
                          setup_dt,
                          removal_dt,
                          pods,
                          items_per_pod,
                          code_csv
                        } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, id)}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none" sx={{}}>
                              <Typography variant="subtitle2" noWrap>
                                {code}
                              </Typography>
                            </TableCell>
                            <TableCell scope="row" padding="none">
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </TableCell>
                            <TableCell scope="row" padding="none">
                              {organizer}
                            </TableCell>
                            <TableCell scope="row" padding="none">
                              {place_1}
                            </TableCell>
                            <TableCell scope="row" padding="none">
                              {code_csv}
                            </TableCell>
                            <TableCell align="right" padding="none">
                              <MoreMenu
                                itemId={id}
                                onEdit={(id) => {
                                  setCurrentTargetId(id);
                                  setUpdateDt(moment().unix());
                                  setModal(true);
                                }}
                                onDelete={(id) => {
                                  setCurrentTargetId(id);
                                  setConf(true);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={7} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isItemNotFound && !isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {isItemNotFound && isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                          <Paper>
                            <Typography gutterBottom align="center" variant="subtitle1">
                              読み込み中
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
              component="div"
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="表示件数"
            />
          </Card>
        </Container>
        <EditModal
          targetId={currentTargetId}
          open={modal}
          onClose={() => {
            setModal(false);
          }}
          updateDt={updateDt}
          reload={(id) => {
            reload(id);
          }}
        />
        <ConfDialog
          open={conf}
          onOk={() => {
            if (!diagOkproc.current) {
              diagOkproc.current = true;
              deleteItems([currentTargetId]);
              setConf(false);
              diagOkproc.current = false;
            }
          }}
          onCancel={() => {
            setConf(false);
          }}
          onClose={() => {
            setConf(false);
          }}
        >
          削除してもよろしいでしょうか？
        </ConfDialog>
        <ConfDialog
          open={confDelAll}
          onOk={() => {
            if (!diagOkproc.current) {
              diagOkproc.current = true;
              deleteItems(selected);
              setSelected([]);
              setConfDelAll(false);
              diagOkproc.current = false;
            }
          }}
          onCancel={() => {
            setConfDelAll(false);
          }}
          onClose={() => {
            setConfDelAll(false);
          }}
        >
          削除してもよろしいでしょうか？ ({selected.length}件)
        </ConfDialog>
      </Page>
    </>
  );
}
