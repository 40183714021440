import { filter, chunk } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef, View } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import backFill from '@iconify/icons-eva/arrow-ios-back-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Backdrop,
  Paper
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar, MoreMenu } from '../../components/incentive';
import IncentiveModal from './IncentiveModal';
import ConfDialog from '../../components/ConfDialog';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'sales_s', label: '売上金額(下限)', alignRight: true },
  { id: 'sales_s', label: '売上金額(上限)', alignRight: true },
  { id: 'commission', label: '手数料率', alignRight: true },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, role) {
  if (query) {
    array = filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  if (role !== -1) {
    array = filter(array, (_target) => _target.role === role);
  }

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Incentive({
  open = false,
  targetId = 0,
  targetCode = '',
  targetName = '',
  onClose,
  updateTargetDt
}) {
  const { SystemLib } = window;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [modal, setModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [currentTargetId, setCurrentTargetId] = useState(0);
  const [updateDt, setUpdateDt] = useState(moment().unix());

  const [isLoading, setIsLoading] = useState(false);
  const [conf, setConf] = useState(false);
  const [confDelAll, setConfDelAll] = useState(false);
  const diagOkproc = useRef(false);

  const [role, setRole] = useState(-1);

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    getItems();
  }, [targetCode, updateTargetDt]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredUsers.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName, role);

  const isUserNotFound = filteredUsers.length === 0;

  const useStyles = makeStyles({
    tableCell: {
      height: 10
    }
  });

  const getItems = async () => {
    setIsLoading(true);
    const { data } = await SystemLib.api(
      'incentive/list',
      {
        commission_code: targetCode
      },
      'post'
    );
    if (data.result) {
      setUsers(data.data);
    }
    setIsLoading(false);
  };

  const getItem = async (id) => {
    const { data } = await SystemLib.api(`incentive/${id}`, {}, 'get');
    if (data.result) {
      let flag = false;
      if (users) {
        const arr = users.slice();
        arr.forEach((v, i) => {
          if (v.id === id) {
            arr[i] = data.data;
            setUsers(arr);
            flag = true;
          }
        });
      }
      if (!flag) {
        setOrderBy(null);
        const arr = users.slice();
        arr.unshift(data.data);
        setUsers(arr);
      }
    }
  };

  const deleteItems = async (ids) => {
    const tmp = chunk(ids, 1000);
    const p = [];
    tmp.forEach(async (arr) => {
      console.log(arr);
      p.push(
        SystemLib.api(
          `incentive/delete`,
          {
            ids: arr
          },
          'post'
        )
      );
    });
    await Promise.all(p);
    const newUsers = users.filter((v) => !ids.includes(v.id));
    setUsers(newUsers);
  };

  const reload = async (id) => {
    getItem(id);
  };

  const classes = useStyles();

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        background: '#fff',
        height: '100%',
        width: '100%',
        display: open ? 'block' : 'none'
      }}
    >
      <Page title="手数料率管理：インセンティブ登録">
        <Container sx={{ position: 'relative' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom display="block">
              {targetName}：インセンティブ登録
            </Typography>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              <Button
                variant="outlined"
                startIcon={<Icon icon={backFill} />}
                onClick={() => {
                  onClose(targetId);
                }}
              >
                戻る
              </Button>
              <Button
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                onClick={() => {
                  setCurrentTargetId(0);
                  setUpdateDt(moment().unix());
                  setModal(true);
                }}
              >
                新規登録
              </Button>
            </Stack>
          </Stack>

          <Card>
            <ListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDelete={() => {
                setConfDelAll(true);
              }}
              onChangeRole={(v) => {
                setRole(v);
              }}
              useMaster={false}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={filteredUsers.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const { id, commission_code, sales_s, sales_e, commission } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;
                        const numberWithComma = new Intl.NumberFormat();

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox" width={10}>
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, id)}
                              />
                            </TableCell>
                            <TableCell align="right" padding="normal" width={200}>
                              {numberWithComma.format(sales_s)}
                            </TableCell>
                            <TableCell align="right" padding="normal" width={200}>
                              {sales_e === 0 ? '' : numberWithComma.format(sales_e)}
                            </TableCell>
                            <TableCell align="right" padding="normal" width={200}>
                              {commission}%
                            </TableCell>
                            <TableCell align="right" padding="normal">
                              <MoreMenu
                                itemId={id}
                                onEdit={(id) => {
                                  setCurrentTargetId(id);
                                  setUpdateDt(moment().unix());
                                  setModal(true);
                                }}
                                onDelete={(id) => {
                                  setCurrentTargetId(id);
                                  setConf(true);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={5} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && !isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {isUserNotFound && isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                          <Paper>
                            <Typography gutterBottom align="center" variant="subtitle1">
                              読み込み中
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[20, 30, 50, 100]}
              component="div"
              count={filteredUsers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="表示件数"
            />
          </Card>
        </Container>
        {modal && (
          <IncentiveModal
            targetId={currentTargetId}
            targetCode={targetCode}
            open={modal}
            onClose={() => {
              setModal(false);
            }}
            updateDt={updateDt}
            reload={(id) => {
              reload(id);
            }}
          />
        )}
        <ConfDialog
          open={conf}
          onOk={() => {
            if (!diagOkproc.current) {
              diagOkproc.current = true;
              deleteItems([currentTargetId]);
              setCurrentTargetId(0);
              setConf(false);
              diagOkproc.current = false;
            }
          }}
          onCancel={() => {
            setConf(false);
          }}
          onClose={() => {
            setConf(false);
          }}
        >
          削除してもよろしいでしょうか？
        </ConfDialog>
        <ConfDialog
          open={confDelAll}
          onOk={() => {
            if (!diagOkproc.current) {
              diagOkproc.current = true;
              deleteItems(selected);
              setSelected([]);
              setConfDelAll(false);
              diagOkproc.current = false;
            }
          }}
          onCancel={() => {
            setConfDelAll(false);
          }}
          onClose={() => {
            setConfDelAll(false);
          }}
        >
          削除してもよろしいでしょうか？ ({selected.length}件)
        </ConfDialog>
      </Page>
    </div>
  );
}
