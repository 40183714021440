import { filter, chunk } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef, View, createElement } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import ArrowBack from '@iconify/icons-eva/arrow-ios-back-outline';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Backdrop,
  Paper,
  Tooltip
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar, MoreMenu } from '../../components/products';
import CollectModal from './CollectModal';
import OrderModal from './OrderModal';
import ConfDialog from '../../components/ConfDialog';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'code', label: ['商品'], alignRight: false },
  // { id: 'num_in_devices', label: ['納入', <br />, 'ロット'], alignRight: false },
  // { id: 'throughput', label: ['製造', <br />, '日数'], alignRight: false },
  // { id: 'expiring', label: ['消費', <br />, '期限'], alignRight: false },
  // { id: 'tekisei_stocks', label: ['適正', <br />, '在荷'], alignRight: false },
  { id: 'device_stocks', label: ['在荷', <br />, '(device)'], alignRight: false },
  { id: 'storage_stocks', label: ['在荷', <br />, '(保管)'], alignRight: false },
  { id: 'stock_sum', label: ['在荷', <br />, '(合計)'], alignRight: false },
  { id: 'stock_diff', label: ['適正', <br />, '差分'], alignRight: false },
  { id: 'shipping', label: '出荷数', alignRight: false },
  { id: 'storage_stocks_real', label: ['出荷後', <br />, '在荷'], alignRight: false },
  { id: 'order_quantity', label: ['仕入', <br />, '予定数'], alignRight: false },
  { id: 'order_dt', label: '発注日', alignRight: false },
  { id: 'stock_expected_dt', label: ['入荷', <br />, '予定日'], alignRight: false },
  { id: 'ext_status', label: ['状況', <br />, '(期限)'], alignRight: false },
  { id: 'stock_status', label: ['状況', <br />, '(在荷)'], alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, exp, rep) {
  if (query) {
    array = filter(
      array,
      (_target) =>
        _target.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _target.code.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  if (exp !== -1) {
    array = filter(array, (_target) => _target.exp_status === exp);
  }
  if (rep !== -1) {
    array = filter(array, (_target) => _target.stock_status === rep);
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Products() {
  const navigate = useNavigate();

  const { SystemLib } = window;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [modalCollect, setModalCollect] = useState(false);
  const [modalOrder, setModalOrder] = useState(false);
  const [list, setList] = useState([]);
  const [updateDt, setUpdateDt] = useState(moment().unix());

  const [currentProductCode, setCurrentProductCode] = useState('');
  const [currentProductName, setCurrentProductName] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [conf, setConf] = useState(false);
  const [confAll, setConfAll] = useState(false);
  const [confOk, setConfOk] = useState(() => {});
  const diagOkproc = useRef(false);

  // フィルタ用
  const [exp, setExp] = useState(-1);
  const [rep, setRep] = useState(-1);

  useEffect(() => {
    getList();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredItems.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredItems = applySortFilter(list, getComparator(order, orderBy), filterName, exp, rep);

  const isItemNotFound = filteredItems.length === 0;

  const useStyles = makeStyles({
    tableCell: {
      height: 10
    }
  });

  const getList = async () => {
    setIsLoading(true);
    const { data } = await SystemLib.api('product/stocks', {}, 'post');
    if (data.result) {
      setList(data.data);
    }
    setIsLoading(false);
  };

  const getItem = async (product_code) => {
    const { data } = await SystemLib.api(
      `product/item`,
      {
        product_code
      },
      'post'
    );
    if (data.result) {
      let flag = false;
      if (list) {
        const arr = list.slice();
        arr.forEach((v, i) => {
          if (v.code === product_code) {
            arr[i] = data.data;
            setList(arr);
            flag = true;
          }
        });
      }
      if (!flag) {
        // setOrderBy(null);
        const arr = list.slice();
        arr.unshift(data.data);
        setList(arr);
      }
    }
  };

  const collectItems = async (ids) => {
    const ret = await SystemLib.api(
      `product/collects`,
      {
        ids
      },
      'post'
    );
    console.log(ret);
    getList();
  };

  const reload = async (product_code) => {
    getItem(product_code);
  };

  const classes = useStyles();

  return (
    <>
      <Page title="在荷商品一覧：回収・仕入指示の発報">
        <Container sx={{ position: 'relative' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              在荷商品一覧：回収・仕入指示の発報
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="outlined"
              startIcon={<Icon icon={ArrowBack} />}
              onClick={() => {
                navigate(-1);
              }}
            >
              戻る
            </Button>
          </Stack>
          <Card>
            <ListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDelete={() => {}}
              onCollect={() => {
                setConfAll(true);
              }}
              onChangeExp={(exp) => {
                setExp(exp);
              }}
              onChangeRep={(rep) => {
                setRep(rep);
              }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={filteredItems.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredItems
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          code,
                          name,
                          price,
                          partner_code,
                          throughput,
                          expiring,
                          created_at,
                          updated_at,
                          deleted_at,
                          num_in_devices,
                          tekisei_stocks,
                          warning_stocks,
                          device_stocks,
                          storage_stocks,
                          stock_sum,
                          stock_diff,
                          shipping,
                          storage_stocks_real,
                          exp_status,
                          stock_status,
                          order_quantity,
                          order_dt,
                          stock_expected_dt
                        } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;
                        let exp_color = null;
                        if (exp_status.match(/注意/)) {
                          exp_color = '#fafad2';
                        } else if (exp_status.match(/必須/) || exp_status.match(/切れ/)) {
                          exp_color = '#fad9d2';
                        }
                        let stock_color = null;
                        if (stock_status.match(/検討/)) {
                          stock_color = '#fafad2';
                        } else if (stock_status.match(/要/)) {
                          stock_color = '#fad9d2';
                        }
                        const numberWithComma = new Intl.NumberFormat();
                        const priceFormat = numberWithComma.format(price);
                        return (
                          <Tooltip
                            key={id}
                            title={[
                              `納入ロット：${num_in_devices}個`,
                              <br />,
                              // eslint-disable-next-line no-irregular-whitespace
                              `製造日数　：中${throughput}日`,
                              <br />,
                              // eslint-disable-next-line no-irregular-whitespace
                              `消費期限　：${expiring}日`,
                              <br />,
                              // eslint-disable-next-line no-irregular-whitespace
                              `適正在荷　：${tekisei_stocks}個`
                            ]}
                          >
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, id)}
                                />
                              </TableCell>
                              <TableCell
                                scope="row"
                                padding="none"
                                sx={{ minWidth: 100, paddingLeft: 2 }}
                              >
                                {code}
                                <br />
                                {name}
                                <br />¥{priceFormat}
                              </TableCell>
                              {/* <TableCell scope="row" padding="none">
                              {num_in_devices}
                                </TableCell>
                                <TableCell scope="row" padding="none">
                                  {throughput}
                                </TableCell>
                                <TableCell scope="row" padding="none">
                                  {expiring}
                                </TableCell> */}
                              {/* <TableCell scope="row" padding="none">
                                {tekisei_stocks}
                              </TableCell> */}
                              <TableCell scope="row" padding="normal">
                                {device_stocks}
                              </TableCell>
                              <TableCell scope="row" padding="normal">
                                {storage_stocks}
                              </TableCell>
                              <TableCell scope="row" padding="normal">
                                {stock_sum}
                              </TableCell>
                              <TableCell scope="row" padding="normal">
                                <Typography
                                  fontSize="0.875rem"
                                  sx={{ color: stock_diff < 0 ? '#f00' : null }}
                                >
                                  {stock_diff}
                                </Typography>
                              </TableCell>
                              <TableCell scope="row" padding="normal">
                                {shipping}
                              </TableCell>
                              <TableCell scope="row" padding="normal">
                                {storage_stocks_real}
                              </TableCell>
                              <TableCell scope="row" padding="normal">
                                {order_quantity}
                              </TableCell>
                              <TableCell scope="row" padding="normal">
                                {order_dt && moment(order_dt).format('M/D')}
                              </TableCell>
                              <TableCell scope="row" padding="normal">
                                {stock_expected_dt && moment(stock_expected_dt).format('M/D')}
                              </TableCell>
                              <TableCell
                                scope="row"
                                padding="none"
                                align="center"
                                sx={{
                                  backgroundColor: exp_color
                                }}
                              >
                                {exp_status}
                              </TableCell>
                              <TableCell
                                scope="row"
                                padding="none"
                                sx={{ backgroundColor: stock_color }}
                                align="center"
                              >
                                {stock_status}
                              </TableCell>
                              <TableCell scope="row" align="right" width={200}>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                  paddingLeft={1}
                                >
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                      setUpdateDt(moment().unix());
                                      setCurrentProductCode(code);
                                      setCurrentProductName(name);
                                      setModalCollect(true);
                                    }}
                                    disabled={selected.length > 0}
                                  >
                                    回収指示
                                  </Button>
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    color="info"
                                    onClick={() => {
                                      setUpdateDt(moment().unix());
                                      setCurrentProductCode(code);
                                      setCurrentProductName(name);
                                      setModalOrder(true);
                                    }}
                                    disabled={selected.length > 0}
                                  >
                                    仕入指示
                                  </Button>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          </Tooltip>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={13} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isItemNotFound && !isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={13} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {isItemNotFound && isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={13} sx={{ py: 3 }}>
                          <Paper>
                            <Typography gutterBottom align="center" variant="subtitle1">
                              読み込み中
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
              component="div"
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="表示件数"
            />
          </Card>
        </Container>
        {modalOrder && (
          <OrderModal
            targetProductCode={currentProductCode}
            open={modalOrder}
            onClose={() => {
              setModalOrder(false);
            }}
            updateDt={updateDt}
            reload={(product_code) => {
              reload(product_code);
            }}
          />
        )}
        {modalCollect && (
          <CollectModal
            targetProductCode={currentProductCode}
            targetProductName={currentProductName}
            open={modalCollect}
            onClose={() => {
              setModalCollect(false);
            }}
            updateDt={updateDt}
            reload={(product_code) => {
              reload(product_code);
            }}
          />
        )}
        <ConfDialog
          open={conf}
          onOk={() => {
            if (!diagOkproc.current) {
              diagOkproc.current = true;
              confOk();
              setConf(false);
              diagOkproc.current = false;
            }
          }}
          onCancel={() => {
            setConf(false);
          }}
          onClose={() => {
            setConf(false);
          }}
        >
          実行してもよろしいでしょうか？
        </ConfDialog>
        <ConfDialog
          open={confAll}
          onOk={() => {
            if (!diagOkproc.current) {
              diagOkproc.current = true;
              collectItems(selected);
              setSelected([]);
              setConfAll(false);
              diagOkproc.current = false;
            }
          }}
          onCancel={() => {
            setConfAll(false);
          }}
          onClose={() => {
            setConfAll(false);
          }}
        >
          実行してもよろしいでしょうか？ ({selected.length}件)
        </ConfDialog>
      </Page>
    </>
  );
}
